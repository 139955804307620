import { ChangeEvent, useState, useEffect, useRef } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { Label } from 'components/Toolkit/Inputs/Label';
import { Inputs } from 'components/Toolkit/Inputs/TextField';
import { SubText } from 'components/Toolkit/Inputs/SubText';
import { ErrorMessage } from 'components/Toolkit/Inputs/ErrorMessage';
import { HelpText } from 'components/Toolkit/Inputs/HelpText';
import { Button } from 'components/Toolkit/Button/Button';

import type { TextFieldProps } from 'components/Toolkit/Inputs/TextField';
import { media } from 'helpers/breakpoints';

import type { ButtonType } from 'components/Toolkit/Button/SharedButton';

type TGapVariant = 'DEFAULT' | 'SMALL';
export interface InputWithIconProps extends TextFieldProps {
  label?: string | ReactNode;
  buttonText?: string;
  handleClick?: (t: string) => void;
  isButtonDisabled?: boolean;
  isInputDisabled?: boolean;
  initialInputValue?: string;
  id?: string;
  name?: string;
  helpText?: string | null;
  hasError?: boolean;
  willUseSubText?: boolean;
  icon: ReactNode | string;
  gapVariant?: TGapVariant;
  fullWidthIcon?: boolean;
  capitalizeLabel?: boolean;
  disableEnter?: boolean;
  isForm?: boolean;
  iconBackgroundColour?: string;
  willUseButton?: boolean;
  ofType?: ButtonType;
}

type TContainer = { gapVariant: TGapVariant };
export const Container = styled.div<TContainer>`
  max-height: 60px;
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-column-gap: ${({ theme }) => theme.spacing.S8};
  position: relative;

  ${media.medium} {
    grid-column-gap: ${({ theme, gapVariant }) =>
      gapVariant === 'DEFAULT' ? theme.spacing.M16 : theme.spacing.S8};
  }

  width: 100%;
`;

type TInputs = {
  hasError: boolean;
  willUseSubText: boolean;
  fullWidthIcon: boolean;
};
const SInputs = styled(Inputs)<TInputs>`
  text-transform: uppercase;
  padding: ${({ theme, fullWidthIcon }) =>
    `${theme.spacing.S8} ${theme.spacing.M16} ${theme.spacing.S8} ${
      fullWidthIcon ? theme.spacing.L40 : theme.spacing.M24
    } !important`}; // '!important' added to avoid padding being overwritten by TextField styles
  ${({ theme, hasError }) =>
    hasError &&
    `border-color: ${theme.colors.RED_DARK}; color: ${theme.colors.RED_DARK};`}
  ${({ theme, willUseSubText }) =>
    willUseSubText ? `margin-bottom: ${theme.spacing.S4}` : ''};

  &::placeholder {
    text-transform: none;
  }
`;

const IconContainer = styled.div`
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 1px;
  height: 38px;
`;

const Icon = styled.img<{ iconBackgroundColour?: string }>`
  height: 100%;
  background-color: ${({ iconBackgroundColour }) =>
    iconBackgroundColour ? iconBackgroundColour : ''};
`;

/**
 * @deprecated for the following reason
 * - Has button coupled within
 * - This component should be handled as a variant of TextField
 */
function InputWithIcon({
  icon,
  buttonText,
  handleClick,
  isButtonDisabled = false,
  isInputDisabled = false,
  label,
  initialInputValue,
  id,
  name,
  errorMessage,
  helpText,
  onChange,
  willUseSubText = true,
  className,
  gapVariant = 'DEFAULT',
  fullWidthIcon = true,
  capitalizeLabel,
  disableEnter = false,
  isForm = true,
  onBlur,
  onFocus,
  iconBackgroundColour,
  willUseButton = true,
  ofType,
  ...rest
}: InputWithIconProps) {
  const [inputValue, setInputValue] = useState<string>(initialInputValue ?? '');
  const alwaysSetId = id ? id : name;
  const inputRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inputValue && !isButtonDisabled) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleClickOutside);
    } else {
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', handleClickOutside);
      };
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleClickOutside);
    };
  }, [inputValue, isButtonDisabled]);

  function handleClickOutside(event: MouseEvent | KeyboardEvent) {
    if (
      (buttonRef.current && buttonRef.current.contains(event.target as Node)) ||
      (inputRef.current && inputRef.current.contains(event.target as Node))
    ) {
      return;
    } else {
      onBlur && onBlur();
    }
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.currentTarget.value;
    setInputValue(value);
    onChange && onChange(value);
  }

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    handleClick && handleClick(inputValue);
  }

  function handleEnterKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && !disableEnter) {
      event.preventDefault();
      handleClick && handleClick(inputValue);
    }
  }

  return (
    <div className={className}>
      {label && (
        <Label htmlFor={alwaysSetId} capitalize={capitalizeLabel}>
          {label}
        </Label>
      )}
      <Container gapVariant={gapVariant}>
        <IconContainer>
          {typeof icon === 'string' ? (
            <Icon
              src={icon}
              alt=""
              width="32"
              height="38"
              iconBackgroundColour={iconBackgroundColour}
            />
          ) : (
            <>{icon}</>
          )}
        </IconContainer>
        <div ref={inputRef}>
          <SInputs
            id={id}
            name={name}
            hasError={Boolean(errorMessage && errorMessage.length > 0)}
            onChange={handleChange}
            value={inputValue}
            fullWidthIcon={fullWidthIcon}
            onKeyPress={handleEnterKeyPress}
            disabled={isInputDisabled}
            willUseSubText={willUseSubText}
            {...rest}
          />
        </div>
        {willUseButton && (
          <div ref={buttonRef}>
            <Button
              type={isForm ? 'submit' : 'button'}
              disabled={isButtonDisabled}
              onClick={handleSubmit}
              ofType="TERTIARY"
            >
              {buttonText}
            </Button>
          </div>
        )}
      </Container>
      {willUseSubText && (
        <SubText>
          {errorMessage ? (
            <ErrorMessage text={errorMessage} />
          ) : (
            helpText && <HelpText text={helpText} />
          )}
        </SubText>
      )}
    </div>
  );
}

export const SContainer = styled.div<TContainer>`
  max-height: 60px;
  width: 100%;
  position: relative;
`;

const IconInput = (
  props: Omit<
    InputWithIconProps,
    'buttonText' | 'handleClick' | 'isButtonDisabled'
  >,
) => {
  const {
    icon,
    isInputDisabled = false,
    label,
    initialInputValue,
    id,
    name,
    errorMessage,
    helpText,
    onChange,
    willUseSubText = true,
    className,
    gapVariant = 'DEFAULT',
    fullWidthIcon = true,
    capitalizeLabel,
    disableEnter = false,
    isForm = true,
    onBlur,
    onFocus,
    iconBackgroundColour,
    ...rest
  } = props;
  const [inputValue, setInputValue] = useState<string>(initialInputValue ?? '');
  const alwaysSetId = id ? id : name;

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.currentTarget.value;
    setInputValue(value);
    onChange && onChange(value);
  }

  return (
    <div className={className}>
      {label && (
        <Label htmlFor={alwaysSetId} capitalize={capitalizeLabel}>
          {label}
        </Label>
      )}
      <SContainer gapVariant={gapVariant}>
        <IconContainer>
          {typeof icon === 'string' ? (
            <Icon
              src={icon}
              alt=""
              width="32"
              height="38"
              iconBackgroundColour={iconBackgroundColour}
            />
          ) : (
            <>{icon}</>
          )}
        </IconContainer>
        <SInputs
          id={id}
          name={name}
          hasError={Boolean(errorMessage && errorMessage.length > 0)}
          onChange={handleChange}
          value={inputValue}
          fullWidthIcon={fullWidthIcon}
          disabled={isInputDisabled}
          willUseSubText={willUseSubText}
          {...rest}
        />
      </SContainer>
      {willUseSubText && (
        <SubText>
          {errorMessage ? (
            <ErrorMessage text={errorMessage} />
          ) : (
            helpText && <HelpText text={helpText} />
          )}
        </SubText>
      )}
    </div>
  );
};

export { InputWithIcon, IconInput };
