import type { AxiosResponse } from 'axios';
import type { IncomingHttpHeaders } from 'http';

import {
  donedealGateway,
  donedealServicesGateway,
} from 'api/api-utils/commonApiUtils';
import type {
  CategoryResponse,
  ReportAdResponse,
  GetSendMessageResponse,
  SendMessageResponse,
  SendEnquiryResponse,
  GetDealerEnquiryResponse,
  VehicleLookUpResponse,
  RetrieveBundlesResponse,
  RetrievePublishFiltersResponse,
  GetPublishFilterValuesResponse,
  EditAdResponse,
  EditAdPayload,
  IDraftAdPayload,
  RefinedVehicleLookupResponse,
  GreenlightLookupResponse,
  RetrievePublishFiltersAlternatesResponse,
  IOrderResponse,
  TGetOrder,
  ICreatePaymentIntentResponse,
  TGetStripePaymentMethods,
  IGetStripePaymentMethodsResponse,
  TRemoveStripePaymentMethod,
  TPayWithPayPal,
  TPayWithPayPalResponse,
  TPayByPhone,
  TPayByVoucher,
  TConfirmPhone,
  IGetPhotosResponse,
  ISendEnquiryPayload,
  GetPostEnquirySimilarAds,
  TGetOrderIDFromAdID,
  UpsellPayload,
  GetOrderResponse,
  UpsellByNamePayload,
} from 'api/types/adwriteApiTypes';

import {
  PublishFiltersNew,
  SubFilterValue,
} from 'components/AdForm/Panels/PublishFilters/types/index';

import type { IFormSubmission } from 'components/AdForm/config/placeAd';
import { IFormSubmissionC2B } from 'components/AdForm/config/placeAd';
import { AdTypes } from 'components/AdForm/types';

async function getSendMessageInitialValue(
  id: number,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<GetSendMessageResponse>> {
  const response: AxiosResponse<GetSendMessageResponse> =
    await donedealGateway.get(`/ddapi/legacy/adwrite/api/v3/contact/ad/${id}`, {
      headers,
    });
  return response;
}

async function getDealerEnquiryInitialValues(
  id: number,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<GetDealerEnquiryResponse>> {
  const response: AxiosResponse<GetDealerEnquiryResponse> =
    await donedealGateway.get(
      `/ddapi/legacy/adwrite/api/v3/contact/dealer/ad/${id}`,
      {
        headers,
      },
    );
  return response;
}

async function getReportAdCategories(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<CategoryResponse[]>> {
  const response: AxiosResponse<CategoryResponse[]> = await donedealGateway.get(
    '/ddapi/legacy/adwrite/api/v3/report/listing/categories',
    {
      headers,
    },
  );
  return response;
}

async function sendMessage(
  payload: {
    id: number;
    message: string;
  },
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<SendMessageResponse>> {
  const response: AxiosResponse<SendMessageResponse> =
    await donedealGateway.post(
      `/ddapi/legacy/adwrite/api/v3/contact/ad/${payload.id}`,
      payload,
      {
        headers,
      },
    );
  return response;
}

async function sendEnquiry(
  payload: ISendEnquiryPayload,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<SendEnquiryResponse>> {
  const response: AxiosResponse<SendEnquiryResponse> =
    await donedealGateway.post(
      `/ddapi/legacy/adwrite/api/v3/contact/dealer/ad/${payload.id}`,
      payload,
      { headers },
    );
  return response;
}

async function reportAd(
  reportAdData: ReportAdResponse,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<ReportAdResponse>> {
  const response: AxiosResponse<ReportAdResponse> = await donedealGateway.post(
    '/ddapi/legacy/adwrite/api/v3/report/listing',
    reportAdData,
    {
      headers,
    },
  );
  return response;
}

async function lookupVehicleRegistrationNumber(
  registrationNumber: string,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<VehicleLookUpResponse>> {
  const response: AxiosResponse<VehicleLookUpResponse> =
    await donedealGateway.get(
      `/ddapi/legacy/adwrite/api/v3/vehicle/lookup/${registrationNumber}`,
      { headers },
    );
  return response;
}

async function getPhotos(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<IGetPhotosResponse[]>> {
  const response = await donedealGateway.get('/ddapi/v1/listings/photos', {
    headers,
  });
  return response;
}

async function rotatePhoto(
  id: string,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<IGetPhotosResponse>> {
  const response = await donedealGateway.put(
    `/ddapi/v1/listings/photos/${id}`,
    undefined,
    {
      headers,
    },
  );
  return response;
}

async function setCoverPhoto(
  id: string,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<any>> {
  const response = await donedealGateway.put(
    `/ddapi/v1/listings/photos/cover/${id}`,
    undefined,
    {
      headers,
    },
  );
  return response;
}

async function deletePhoto(
  id: string,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<any>> {
  const response = await donedealGateway.delete(
    `/ddapi/v1/listings/photos/${id}`,
    {
      headers,
    },
  );
  return response;
}

async function resetPhotos(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<any>> {
  const response = await donedealGateway.delete('ddapi/v1/listings/photos', {
    headers,
  });
  return response;
}

async function createAd(
  payload: IFormSubmission,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<null>> {
  const response: AxiosResponse<null> = await donedealGateway.post(
    '/ddapi/v1/listings',
    payload,
    { headers },
  );
  return response;
}

/**
 * Calls createAd with hardcoded values that don't change
 */
async function createAdC2B(
  payload: IFormSubmissionC2B,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<null>> {
  return createAd(
    {
      ...payload,
      emailResponse: true,
      phoneResponse: true,
      section: { name: 'cars' },
      adTypes: ['c2b'],
      currency: 'EUR',
    },
    headers,
  );
}

async function getEditAd(
  id: string,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<EditAdResponse>> {
  const response: AxiosResponse<EditAdResponse> = await donedealGateway.get(
    `/ddapi/v1/listings/update/${id}`,
    {
      headers,
    },
  );
  return response;
}

async function getEditAdPublishFilterValues(
  section: string,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<PublishFiltersNew[]>> {
  const response: AxiosResponse<PublishFiltersNew[]> =
    await donedealGateway.get(`/ddapi/v1/edit/filters/${section}`, {
      headers,
    });
  return response;
}

async function updateAd(
  id: string,
  payload: Partial<EditAdPayload>,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<any>> {
  const response: AxiosResponse<any> = await donedealGateway.post(
    `/ddapi/v1/listings/update/${id}`,
    payload,
    { headers },
  );
  return response;
}

async function resetAd(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<any>> {
  const response = await donedealGateway.post(
    '/ddapi/legacy/adwrite/api/v4/reset/uploads/publish',
    undefined,
    { headers },
  );
  return response;
}

async function retrieveBundlesC2B(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<number>> {
  const response: AxiosResponse<number> = await donedealGateway.post(
    `/ddapi/v1/orders/bundles/c2b/cars`,
    {},
    { headers },
  );
  return response;
}

async function retrieveBundles(
  sectionName: string,
  payload: {
    currency: string;
    adTypes: AdTypes[];
    price?: string;
    adId?: number;
    photoCount?: number;
  },
  republish: boolean = false,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<RetrieveBundlesResponse>> {
  const response: AxiosResponse<RetrieveBundlesResponse> =
    await donedealGateway.post(
      `/ddapi/v2/orders/bundles/${sectionName}${
        republish ? '?republish=true' : ''
      }`,
      payload,
      {
        headers,
      },
    );
  return response;
}

async function getPublishFilters(
  sectionName: string,
): Promise<AxiosResponse<RetrievePublishFiltersResponse>> {
  const response: AxiosResponse<RetrievePublishFiltersResponse> =
    await donedealGateway.get(
      `/ddapi/legacy/adwrite/api/v4/sections/filters/publish/${sectionName}`,
    );

  return response;
}

async function getPublishFiltersAlternates(
  sectionName: string,
): Promise<AxiosResponse<RetrievePublishFiltersAlternatesResponse>> {
  const response: AxiosResponse<RetrievePublishFiltersAlternatesResponse> =
    await donedealGateway.get(
      `/ddapi/legacy/adwrite/api/v3/sections/filters/publish/${sectionName}/alternates`,
    );

  return response;
}

async function getPublishFilterValues(
  sectionName: string,
  parentFilterName: string,
  selectionName: string,
): Promise<AxiosResponse<GetPublishFilterValuesResponse>> {
  const response: AxiosResponse<GetPublishFilterValuesResponse> =
    await donedealGateway.get(
      `/ddapi/legacy/adwrite/api/v3/sections/filters/publish/${sectionName}/${parentFilterName}/${selectionName}/values`,
    );

  return response;
}

async function publishDraftAd(
  payload: Partial<IDraftAdPayload>,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<string>> {
  const response: AxiosResponse<string> = await donedealGateway.put(
    '/ddapi/legacy/adwrite/api/v3/create/ad/draft',
    payload,
    { headers },
  );
  return response;
}

async function getDraftAd(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<IDraftAdPayload>> {
  const response: AxiosResponse<IDraftAdPayload> = await donedealGateway.get(
    '/ddapi/legacy/adwrite/api/v3/create/ad/draft',
    { headers },
  );
  return response;
}

async function refinedVehicleRegistrationNumberLookup(
  registrationNumber: string,
  sectionName: string,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<RefinedVehicleLookupResponse>> {
  const response: AxiosResponse<RefinedVehicleLookupResponse> =
    await donedealGateway.post(
      `/ddapi/v1/vehicle/lookup`,
      {
        registrationNumber,
        sectionName,
      },
      { headers },
    );
  return response;
}

async function greenlightVerifyOwnershipLookup(
  registrationNumber: string,
  registrationCertNumber: string,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<GreenlightLookupResponse>> {
  const response: AxiosResponse<GreenlightLookupResponse> =
    await donedealGateway.post(
      'ddapi/v1/vehicle/verify',
      {
        registrationNumber,
        registrationCertNumber,
      },
      { headers },
    );
  return response;
}

async function submitMissingDetails(
  payload: {
    registrationNumber: string;
    message: string;
  },
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<null>> {
  const response: AxiosResponse<null> = await donedealGateway.post(
    '/ddapi/legacy/adwrite/api/v3/vehicle/enquiry/send',
    payload,
    { headers },
  );
  return response;
}

const getOrder: TGetOrder = async (id, headers) => {
  const response: AxiosResponse<IOrderResponse> = await donedealGateway.get(
    `/ddapi/legacy/adwrite/api/v4/payments/pay/${id}`,
    { headers },
  );
  return response;
};

const getOrderIDFromAdID: TGetOrderIDFromAdID = async ({ adID }, headers) => {
  const response: AxiosResponse<GetOrderResponse> = await donedealGateway.get(
    `/ddapi/v1/orders/ad/${adID}/pending`,
    { headers },
  );
  return response;
};

const createPaymentIntent = async (
  payload: { order_id: string },
  headers: IncomingHttpHeaders,
) => {
  const response: AxiosResponse<ICreatePaymentIntentResponse> =
    await donedealGateway.post(
      `/ddapi/legacy/adwrite/api/v3/stripe/payment/payment-intent-web`,
      payload,
      { headers },
    );
  return response;
};

const getStripePaymentMethods: TGetStripePaymentMethods = async (
  userId,
  headers,
) => {
  const response: AxiosResponse<IGetStripePaymentMethodsResponse[]> =
    await donedealGateway.get(
      `/ddapi/legacy/adwrite/api/v3/stripe/payment/payment-methods/${userId}`,
      { headers },
    );
  return response;
};

const removeStripePaymentMethod: TRemoveStripePaymentMethod = async (
  paymentMethodId,
) => {
  const response: AxiosResponse<null> = await donedealGateway.delete(
    `/ddapi/legacy/adwrite/api/v3/stripe/payment/payment-method/remove/${paymentMethodId}`,
    {},
  );
  return response;
};

const payWithPayPal: TPayWithPayPal = async (url, data, formToken, headers) => {
  const response: AxiosResponse<TPayWithPayPalResponse> =
    await donedealGateway.post(url, data, {
      headers: {
        formtoken: formToken,
        ...headers,
      },
    });
  return response;
};

const payByPhone: TPayByPhone = async (orderId, data, formToken, headers) => {
  const response: AxiosResponse = await donedealGateway.post(
    `/ddapi/legacy/adwrite/api/v3/payments/pay/${orderId}`,
    data,
    {
      headers: {
        formToken: formToken,
        ...headers,
      },
    },
  );
  return response;
};

const confirmPhone: TConfirmPhone = async (orderId, data, headers) => {
  const response: AxiosResponse = await donedealGateway.post(
    `/ddapi/legacy/adwrite/api/v3/payments/phone/${orderId}`,
    data,
    {
      headers,
    },
  );

  return response;
};

const payByVoucher: TPayByVoucher = async (
  orderId,
  data,
  formToken,
  headers,
) => {
  const response: AxiosResponse = await donedealGateway.post(
    `/ddapi/legacy/adwrite/api/v3/payments/pay/${orderId}`,
    data,
    {
      headers: {
        formToken: formToken,
        ...headers,
      },
    },
  );
  return response;
};

// New Publish Filter APIs
async function getPublishFiltersNew(
  sectionName: string,
): Promise<AxiosResponse<PublishFiltersNew[]>> {
  const response: AxiosResponse<PublishFiltersNew[]> =
    await donedealGateway.get(`/ddapi/v1/publish/filters/${sectionName}`, {});

  return response;
}

async function getPublishFiltersNewAlternates(
  sectionName: string,
): Promise<AxiosResponse<PublishFiltersNew[]>> {
  const response: AxiosResponse<PublishFiltersNew[]> =
    await donedealGateway.get(
      `/ddapi/v1/publish/filters/${sectionName}/alternates`,
    );

  return response;
}

async function getPublishFilterNewAlternateValues(
  sectionName: string,
  selectionName: string,
): Promise<AxiosResponse<SubFilterValue[]>> {
  const response: AxiosResponse<SubFilterValue[]> = await donedealGateway.get(
    `/ddapi/v1/publish/filters/${sectionName}/${selectionName}`,
  );

  return response;
}

async function getPostEnquirySimilarAds(
  adId: number,
  headers: IncomingHttpHeaders,
) {
  const response: AxiosResponse<GetPostEnquirySimilarAds[]> =
    await donedealGateway.get(`/ddapi/v1/listings/${adId}/post-enquiry`, {
      headers,
    });
  return response;
}

const upsell = async (payload: UpsellPayload, headers: IncomingHttpHeaders) => {
  const response: AxiosResponse<GetOrderResponse> = await donedealGateway.post(
    '/ddapi/v1/orders/upsell',
    payload,
    { headers },
  );
  return response;
};

const upsellByName = async (
  payload: UpsellByNamePayload,
  headers: IncomingHttpHeaders,
) => {
  const response: AxiosResponse<number> = await donedealServicesGateway.get(
    `/ddapi/v1/orders/upsell/${payload.product}/ad/${payload.adId}`,
    { headers },
  );
  return response;
};

export const adWriteApi = {
  getSendMessageInitialValue,
  getDealerEnquiryInitialValues,
  sendMessage,
  getReportAdCategories,
  reportAd,
  lookupVehicleRegistrationNumber,
  sendEnquiry,
  createAd,
  createAdC2B,
  retrieveBundles,
  retrieveBundlesC2B,
  resetAd,
  getPhotos,
  setCoverPhoto,
  rotatePhoto,
  deletePhoto,
  resetPhotos,
  getPublishFilters,
  getPublishFiltersAlternates,
  getPublishFilterValues,
  getEditAd,
  getEditAdPublishFilterValues,
  updateAd,
  publishDraftAd,
  getDraftAd,
  refinedVehicleRegistrationNumberLookup,
  greenlightVerifyOwnershipLookup,
  submitMissingDetails,
  getOrder,
  getOrderIDFromAdID,
  createPaymentIntent,
  getStripePaymentMethods,
  removeStripePaymentMethod,
  payWithPayPal,
  payByPhone,
  confirmPhone,
  payByVoucher,
  getPublishFiltersNew,
  getPublishFiltersNewAlternates,
  getPublishFilterNewAlternateValues,
  getPostEnquirySimilarAds,
  upsell,
  upsellByName,
};
